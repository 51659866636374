/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import PageHeader from "../components/elements/PageHeader/PageHeader";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    img: "img",
    a: "a",
    h3: "h3",
    blockquote: "blockquote",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(PageHeader, {
    title: "アクセスマップ"
  }), "\n", React.createElement(_components.h2, null, "周辺地図"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/access/MAP01.jpg",
    alt: "周辺地図"
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://goo.gl/maps/VgesvG9Xouen7hXx9"
  }, "▶ Google MAP で見る")), "\n", React.createElement(_components.h3, null, "高田馬場本館"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "■ 住所\n東京都新宿区下落合 1-5-16\n■TEL\n", React.createElement("a", {
    href: "tel:03-3365-6141"
  }, "03-3365-6141")), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "路線・経路案内"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/access/MAP02.jpg",
    alt: "路線・経路案内"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
